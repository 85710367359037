import styled from 'styled-components';

export const StyledReportingMainCss = styled.div`
  .st0 {
    display: none;
  }
  .st1 {
    display: inline;
  }
  .st2 {
    display: inline;
    stroke: #ffffff;
    stroke-width: 0.2;
    stroke-miterlimit: 10;
  }
  .st3 {
    display: inline;
    fill: none;
    stroke: #e0e0e0;
    stroke-miterlimit: 10;
  }
  .st4 {
    display: inline;
    fill: none;
    stroke: #000000;
    stroke-width: 1.4;
    stroke-miterlimit: 10;
  }
  .st5 {
    fill: #51b7b0;
    stroke: #4fb7b0;
    stroke-width: 2.232;
    stroke-miterlimit: 10;
  }
  .st6 {
    fill: none;
    stroke: #53b7b0;
    stroke-width: 10.8;
    stroke-miterlimit: 10;
  }
  .st7 {
    display: inline;
    stroke: #222020;
    stroke-miterlimit: 10;
  }
  .st8 {
    display: inline;
    fill: none;
    stroke: #222020;
    stroke-miterlimit: 10;
  }
  .st9 {
    stroke: #222020;
    stroke-miterlimit: 10;
  }
`;
