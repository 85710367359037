/* eslint-disable react/no-danger,no-underscore-dangle */

/**
 * All of this magic helps us to get server side rendering of material-ui styles
 * working with Next.js
 *
 * Based on:
 * https://github.com/adrianmcli/styled-mui-next/tree/master
 * https://github.com/mui-org/material-ui/tree/master/examples/nextjs
 */

import { SheetsRegistry } from 'jss';
import { createGenerateClassName, createTheme, StylesProvider } from '@material-ui/core/styles';
import React from 'react';
import flush from 'styled-jsx/server';

export const STYLE_ELEMENT_ID = 'material-server-side';

const createMaterialContext = () => ({
  theme: createTheme({}),
  sheetsManager: new Map(),
  sheetsRegistry: new SheetsRegistry(),
  generateClassName: createGenerateClassName({
    productionPrefix: 'lodgelink', // customize the prefix
    disableGlobal: true // Disable global class names to prevent conflicts
  })
});

export const getMaterialContext = () => {
  if (!process.browser) {
    return createMaterialContext();
  }

  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createMaterialContext();
  }

  return global.__INIT_MATERIAL_UI__;
};

export const getMaterialStyleElement = materialContext => (
  <>
    {/**
     * Currently, the materialContext.sheetsRegistry shows an empty array, and there is no .add() method to add any styles.
     * Once we confirm that we won't use this in the future, we can remove it.
     *
     * Additionally, we may not use this sheetsRegistry properly
     * Reference: https://github.com/mui/material-ui/issues/9097#issuecomment-364947401
     * More Context: https://github.com/Lodgelink-Inc/admin-portal/pull/70#issuecomment-2679864998
     */}
    <style
      id={STYLE_ELEMENT_ID}
      dangerouslySetInnerHTML={{
        __html: materialContext.sheetsRegistry.toString()
      }}
    />
    {flush() || null}
  </>
);

export const withMaterialSSR = (App, materialContext, props) => (
  <StylesProvider sheetsManager={materialContext.sheetsManager} generateClassName={materialContext.generateClassName}>
    <App {...props} />
  </StylesProvider>
);
