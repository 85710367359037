import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import throttle from 'lodash.throttle';
import { withRouter } from 'next/router';
import Adaptive from '../../Adaptive/Adaptive';
import DesktopNav from './DesktopNav';
import { NavWrapper, NavBorder } from './MainNav.styles';
// touch context
import { IsTouchEnabledContext } from './common/Context';
import SearchBar from './common/SearchBar';
import MobileNav from './MobileNav';

/**
 * This constant reflects the state nature of the navigation
 * The menu can either be in its normal state (page load);
 * In a forced hidden state (when we are scrolling down the page)
 * In a forced visible state (when we are scrolling up the page.)
 */
export const NAV_STATE = {
  STATIC: 'nav-static',
  SHOW: 'nav-visible',
  HIDE: 'nav-hidden'
};

const {
  publicRuntimeConfig: { CALL_JOIN_NOW_ENDPOINT }
} = getConfig();

class MainNavPre extends Component {
  static propTypes = {
    /**
     * Passed Component of the link that takes a user to the homepage.
     */
    logo: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * The Passed component housing the search feature.
     */
    search: PropTypes.node,

    /**
     * An array of objects used to create functional nav links.
     */
    mainLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * Flagging whether user is logged in or not to determine wether to render user in nav with submenu or the passed joinLink.
     */
    authenticated: PropTypes.bool,

    /**
     * Passed component that will render in place of user info if authentication i false.
     */
    joinLink: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Authenticated Users First Name.
     */

    userFirstName: PropTypes.string,

    /**
     * Authenticated Users Last Name.
     */

    userLastName: PropTypes.string,

    /**
     * Authenticated Users Currently active organization id.
     */

    userCurrentOrganization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * Authenticated Users Profile Pic (optional), defaults to users initials from First/Last Name.
     */

    userProfilePicture: PropTypes.string,

    /**
     * List of Organizations the Authenticated user is associated to that when clicked sets current active organization
     */

    organizations: PropTypes.arrayOf(PropTypes.shape({})),

    /**
     * Link to the Current Organizations settings page
     */

    organizationSettings: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Change handler to send back selected org so all places can be updated by parent/ancestor
     */
    organizationChange: PropTypes.func,

    /**
     * Link to the Join Organization page.
     */
    joinOrganization: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * List of links available to the authenticated user.
     */
    subMenuLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        href: PropTypes.string,
        label: PropTypes.string,
        className: PropTypes.string,
        target: PropTypes.string,
        onClick: PropTypes.func
      })
    ),

    /**
     * Link to sign out the current authenticated user.
     */
    signOut: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Link to sign in a user.
     */
    signIn: PropTypes.shape({
      id: PropTypes.string,
      href: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
      target: PropTypes.string,
      onClick: PropTypes.func
    }),

    /**
     * Array of notification components provided by the navigation.
     */
    notifications: PropTypes.arrayOf(PropTypes.node),

    /**
     * String used on mobile only to indicate what the icon is.
     */
    notificationsLabel: PropTypes.string,

    /**
     * String message telling the user they do not have notifications.
     */
    noNotificationsMsg: PropTypes.string,

    /**
     * Bool telling if the search is focused or not.
     */
    searchIsFocused: PropTypes.bool,

    /**
     * Bool focring the state of the nav to always have search open. will not focus it.
     */
    stickSearchOpen: PropTypes.bool,

    /**
     * Ref to be passed down to search input
     */
    searchFieldRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),

    /**
     * string of the telephone number for contacting lodgelink. only for mobile.
     */
    contactNumber: PropTypes.string,

    /**
     * function fired on the click of the mobile contact icon.
     */
    onContactClick: PropTypes.func,

    /**
     * Boolean to tell the navs to not render notifications.
     */
    suppressNotifications: PropTypes.bool,
    isDemoSite: PropTypes.bool
  };

  static defaultProps = {
    authenticated: false,
    contactNumber: null,
    onContactClick: () => {},
    joinLink: null,
    joinOrganization: null,
    logo: null,
    mainLinks: [],
    notifications: [],
    notificationsLabel: 'Notifications',
    noNotificationsMsg: 'You have no notifications',
    organizations: [],
    organizationChange: () => {},
    organizationSettings: null,
    search: null,
    searchIsFocused: false,
    searchFieldRef: React.createRef(),
    signOut: null,
    signIn: null,
    stickSearchOpen: false,
    suppressNotifications: false,
    subMenuLinks: [],
    userCurrentOrganization: '',
    userFirstName: '',
    userLastName: '',
    userProfilePicture: null,
    isDemoSite: false
  };

  constructor(props) {
    super(props);

    this.state = {
      isNavVisible: NAV_STATE.STATIC,
      mainMenuVisible: true,
      mobileSearchOpen: false,
      lastMobileState: null,
      isTouchDevice: false
    };

    // keep track of the scroll position
    this.lastScrollY = 0;
    // prevent infinite loop check var.
    this.touchCheckDone = false;
    // prevent nav update for mobile needs
    this.preventNavUpdate = false;

    this.handleScrolling = this.handleScrolling.bind(this);
    this.throttleScrolling = throttle(this.handleScrolling, 50);
    this.mobileSearchCB = this.mobileSearchCB.bind(this);
    this.mobileSearchStateCB = this.mobileSearchStateCB.bind(this);
    this.testForTouch = this.testForTouch.bind(this);
    this.forceScrollCB = this.forceScrollCB.bind(this);
  }

  componentDidMount() {
    if (window) window.addEventListener('scroll', this.throttleScrolling);

    if (!this.touchCheckDone) {
      const touchEnabled = this.testForTouch(window, document, navigator);
      const touchClass = touchEnabled ? 'touch' : 'no-touch';

      document.querySelector('.lodgelink-main-navigation').classList.add(touchClass);
    }
  }

  componentWillUnmount() {
    this.throttleScrolling.cancel();

    if (window) window.removeEventListener('scroll', this.throttleScrolling);
  }

  // set state we are on a touch device.
  testForTouch(docWindow, docDocument, docNavigator) {
    const isTouchDevice =
      !!(
        typeof docWindow !== 'undefined' &&
        ('ontouchstart' in docWindow ||
          (docWindow.DocumentTouch &&
            typeof docDocument !== 'undefined' &&
            docDocument instanceof docWindow.DocumentTouch))
      ) ||
      !!(
        typeof docNavigator !== 'undefined' &&
        ((docNavigator.maxTouchPoints && docWindow.innerWidth <= 1024) || docNavigator.msMaxTouchPoints)
      );

    this.touchCheckDone = true;
    this.setState({ isTouchDevice });

    return isTouchDevice;
  }

  // callback function to allow us to focus on search input when search menu opens
  mobileSearchCB(boolFlag) {
    this.setState({ mobileSearchOpen: boolFlag });
  }

  // callback function to be able to provide last search state back to mobile when it was remounted with sticky option.
  mobileSearchStateCB(stateObj) {
    this.setState({ lastMobileState: stateObj });
  }

  forceScrollCB(yesNo) {
    if (yesNo) {
      this.preventNavUpdate = true;
    } else {
      this.preventNavUpdate = false;
    }
  }

  handleScrolling() {
    const { searchIsFocused, searchFieldRef } = this.props;
    const { mobileSearchOpen } = this.state;
    const NAV_HEIGHT = 80;
    const NAV_HIDE_THRESHOLD = NAV_HEIGHT * 2;

    // figure out scroll direction and set nav state.
    const figureNavState = (oldY, newY) => {
      const { isNavVisible } = this.state;
      const passedNavThreshold = newY > NAV_HIDE_THRESHOLD;
      let newNavState = isNavVisible;

      // if the mobile search is open and focused, blur it.
      if (searchIsFocused && mobileSearchOpen && window.innerWidth < 1024) {
        searchFieldRef.current.blur();
      }

      if (newY > oldY) {
        // we are scrolling down
        newNavState = passedNavThreshold ? NAV_STATE.HIDE : NAV_STATE.STATIC;
      } else if (oldY > newY) {
        // we are scrolling up
        switch (passedNavThreshold) {
          case true:
            if (newNavState !== NAV_STATE.SHOW) newNavState = NAV_STATE.SHOW;
            break;
          case false:
            if (newNavState !== NAV_STATE.SHOW) newNavState = NAV_STATE.STATIC;
            break;
          default:
            break;
        }
      }

      // don't trigger out when we are not in show state
      if (isNavVisible === NAV_STATE.STATIC && newNavState === NAV_STATE.HIDE) {
        return {
          isNavVisible,
          mainMenuVisible: !passedNavThreshold
        };
      }

      return {
        isNavVisible: newNavState,
        mainMenuVisible: !(isNavVisible === NAV_STATE.HIDE)
      };
    };

    const currentScrollY = window.scrollY || document.documentElement.scrollTop;
    const newState = figureNavState(this.lastScrollY, currentScrollY);
    this.lastScrollY = currentScrollY;
    if (!this.preventNavUpdate) {
      this.setState(newState);
    }
  }

  render() {
    const { isNavVisible, mainMenuVisible, lastMobileState, isTouchDevice } = this.state;
    const shouldExposeButtons = CALL_JOIN_NOW_ENDPOINT;
    const {
      logo,
      search,
      mainLinks,
      authenticated,
      notifications,
      notificationsLabel,
      noNotificationsMsg,
      joinLink,
      userFirstName,
      userLastName,
      userCurrentOrganization,
      userProfilePicture,
      organizations,
      organizationSettings,
      organizationChange,
      joinOrganization,
      contactNumber,
      onContactClick,
      subMenuLinks,
      searchFieldRef,
      signOut,
      signIn,
      stickSearchOpen,
      suppressNotifications,
      isDemoSite,
      router
    } = this.props;
    const pageRouteName = router.pathname;
    const logoSelectedIcon = isDemoSite ? 'lodgelinkdemo' : 'lodgelinkcolor';
    const isNotInCrewRegistration = !pageRouteName.startsWith('/CrewMemberRegistration');
    const enabledJoinLink = shouldExposeButtons === 'true' && isNotInCrewRegistration ? joinLink : null;
    const enabledSignIn = isNotInCrewRegistration ? signIn : null;
    const isInBookingFlow = pageRouteName.startsWith('/BookingEdit/');

    return (
      <NavWrapper className={`${isNavVisible} lodgelink-main-navigation`}>
        <NavBorder className={isNavVisible === NAV_STATE.SHOW && 'mui-fixed'} isInBookingFlow={isInBookingFlow}>
          <IsTouchEnabledContext.Provider
            // eslint-disable-next-line
            value={{
              isTouchDevice
            }}
          >
            <Adaptive
              sm={
                <MobileNav
                  logo={logo}
                  search={search}
                  mainLinks={mainLinks}
                  authenticated={authenticated}
                  notifications={notifications}
                  noNotificationsMsg={noNotificationsMsg}
                  suppressNotifications={suppressNotifications}
                  joinLink={enabledJoinLink}
                  userFirstName={userFirstName}
                  userLastName={userLastName}
                  userCurrentOrganization={userCurrentOrganization}
                  userProfilePicture={userProfilePicture}
                  organizations={organizations}
                  organizationSettings={organizationSettings}
                  organizationChange={organizationChange}
                  joinOrganization={joinOrganization}
                  subMenuLinks={subMenuLinks}
                  searchFieldRef={searchFieldRef}
                  signOut={signOut}
                  signIn={enabledSignIn}
                  notificationsLabel={notificationsLabel}
                  contactNumber={contactNumber}
                  onContactClick={onContactClick}
                  stickSearchOpen={stickSearchOpen}
                  mainMenuVisible
                  mobileSearchCB={this.mobileSearchCB}
                  mobileSearchStateCB={this.mobileSearchStateCB}
                  lastMobileState={lastMobileState}
                  forceScrollCB={this.forceScrollCB}
                  logoSelectedIcon={logoSelectedIcon}
                />
              }
              lg={
                <DesktopNav
                  logo={logo}
                  search={search}
                  mainLinks={mainLinks}
                  authenticated={authenticated}
                  notifications={notifications}
                  noNotificationsMsg={noNotificationsMsg}
                  suppressNotifications={suppressNotifications}
                  joinLink={enabledJoinLink}
                  userFirstName={userFirstName}
                  userLastName={userLastName}
                  userCurrentOrganization={userCurrentOrganization}
                  userProfilePicture={userProfilePicture}
                  organizations={organizations}
                  organizationSettings={organizationSettings}
                  organizationChange={organizationChange}
                  joinOrganization={joinOrganization}
                  subMenuLinks={subMenuLinks}
                  searchFieldRef={searchFieldRef}
                  signOut={signOut}
                  signIn={enabledSignIn}
                  mainMenuVisible={mainMenuVisible}
                  isTouchDevice={isTouchDevice}
                  logoSelectedIcon={logoSelectedIcon}
                />
              }
            />
          </IsTouchEnabledContext.Provider>
        </NavBorder>
      </NavWrapper>
    );
  }
}

const MainNav = withRouter(MainNavPre);

export default { MainNav, SearchBar };
