import styled from 'styled-components';
import topography64 from './static/topography-tile.js';

export const StyledBackgroundWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${topography64});
  position: relative;

  /* Adjust opacity of the background w/ this trick */
  &::before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
  }
`;
