import styled from 'styled-components';
import { mixins, colors } from '../../../css';

export const StyledFooterLink = styled.a`
  padding: 0 ${mixins.pxToRem(5)} 0 0;
  color: ${colors.colorWhite};
  text-decoration: none;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;
