import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';
import SVGIcon from '../../../../SVGIcon/SVGIcon';
import Text from '../../../../BuildingBlocks/Text';
import { colors, fonts, mixins, media } from '../../../../css';

export const StyledOrgSelect = styled(Select)`
  width: 100%;
  &&&& {
    border: none;
    background: none;
    color: ${colors.colorGrey};
    font-family: ${fonts.fontBold};
    padding: ${mixins.pxToRem(15)} ${mixins.pxToRem(25)};
    ${media.greaterThan('lg')`padding: ${mixins.pxToRem(15)} ${mixins.pxToRem(20)};`}
    margin: ${mixins.pxToRem(10)} 0;
    position: relative;

    &:before {
      content: '';
      background: ${colors.colorGrey04};
      height: 1px;
      width: calc(100% - 50px);
      ${media.greaterThan('lg')`width: calc(100% - 40px);`}
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      border: none !important;
    }

    &:after {
      content: '';
      background: ${colors.colorGrey04};
      height: 1px;
      width: calc(100% - 50px);
      ${media.greaterThan('lg')`width: calc(100% - 40px);`}
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      border: none !important;
    }
  }
  div[aria-haspopup='true'] {
    background: none;
    &:focus {
      background: none;
    }
    p {
      font-family: ${fonts.fontBold};
    }
  }
`;

export const StyledSelectItem = styled(MenuItem)`
  && {
    align-items: baseline;
    background: none;

    &:focus {
      background: none;
    }
  }
`;

export const StyledMenuText = styled(Text.CopyParagraph)`
  display: inline-block;
  margin: 0;
`;

export const StyledArrow = styled(SVGIcon)`
  position: absolute;
  top: 50%;
  right: 0;
  pointer-events: none;
  transform: translateY(-50%) rotate(${({ rotate }) => rotate || 0}deg);
`;

export const StyledSingleOrg = styled.div`
  border-top: 1px solid ${colors.colorGrey05};
  border-bottom: 1px solid ${colors.colorGrey05};
  margin: ${mixins.pxToRem(10)} 0;
  padding: ${mixins.pxToRem(15)} ${mixins.pxToRem(20)};
`;
