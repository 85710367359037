import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import SVGIcon from '../../../SVGIcon';
import Text from '../../../BuildingBlocks/Text';
import { colors, media, mixins } from '../../../css';

export const StyledFormControl = styled(FormControl)`
  p {
    color: ${colors.colorWhite};
  }
`;

export const StyledLocaleSelect = styled(Select)`
  && {
    border: none;
    color: ${colors.colorWhite};
    align-items: baseline;
    text-decoration: none;
    line-height: 0;
    padding-bottom: ${mixins.pxToRem(45)};
    ${media.greaterThan('lg')`
      padding-bottom: 0;
    `};

    &:before {
      content: none;
    }

    &:after {
      content: none;
    }
  }
`;

export const StyledMenuText = styled(Text.CopyParagraph)`
  padding-left: ${mixins.pxToRem(30)};
  padding-right: ${mixins.pxToRem(10)};
  position: relative;
  top: ${mixins.pxToRem(-6)};
  display: inline-block;
`;

export const StyledMenuItem = styled(MenuItem)`
  && {
    align-items: baseline;
  }
`;

export const StyledArrowSVG = styled(SVGIcon)`
  top: calc(50% - 15px);
  right: 0;
  position: absolute;
  pointer-events: none;
`;
