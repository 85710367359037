import get from 'lodash.get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { flowRight as compose } from 'lodash';
import SVGIcon from '../../components/SVGIcon';
import Grid from '../../components/Utility/Grid';
import Footer from '../../components/Navigation/Footer/Footer';
import MainNav from '../../components/Navigation/MainNav/MainNav';

import routes from '../../routes';

import MainNavContainer from './MainNavContainer';
import FooterContainer from './FooterContainer';
import PropertySearchContainer from '../../containers/PropertySearch';
import withRouting, { withRoutingPropType } from '../../hocs/withRouting';

import { LayoutFooter, Layout, LayoutNav, LayoutPageContent, StyledLink } from './styles';

/**
 * The DefaultLayout component wraps the content of pages with common elements such
 * as a global navigation and footer.
 */
class DefaultLayout extends Component {
  // To disable the footer on a route add the route name the the below array
  static FOOTER_DISABLED_ROUTES = [
    'Map',
    'SignIn',
    'ForgotPassword',
    'ResetPassword',
    'Token',
    'SignUp',
    'OnboardingOptionsPublic',
    'OnboardingStatusPublic'
  ];

  // To disable the nav on a route add the route name to the below array
  static NAV_DISABLED_ROUTES = [
    'SignIn',
    'ForgotPassword',
    'ResetPassword',
    'Token',
    'SignUp',
    'CreateProfile',
    'ProfileCreated',
    'OnboardingOptions',
    'OnboardingOptionsPublic',
    'CreateOrganization',
    'OnboardingStatus',
    'OnboardingStatusPublic'
  ];

  // To force the search field open on a route add the route name to the below array
  static NAV_SEARCH_OPEN_ROUTES = ['Map'];

  static NAV_SEARCH_DISABLED_ROUTES = ['Dashboard', 'BookingDetailsExternal'];

  static propTypes = {
    /** Page component to be wrapped with this layout. */
    children: PropTypes.node,
    router: withRoutingPropType.isRequired
  };

  static defaultProps = {
    children: null
  };

  constructor(props) {
    super(props);

    this.state = {
      // Masks the contents of the page while loading so you don't see the page shifting
      // around from the 'sm' layout (ssr) to whatever your current breakpoint is. This
      // has a negative effect on perceived page load performance but it hides a lot of
      // jank as the page loads.
      layoutHidden: true
    };

    // This ref connects MainNav.MainNav and MainNav.SearchBar together so that the nav
    // can apply focus to the search bar (used for mobile nav)
    this.searchFieldRef = React.createRef();
  }

  componentDidMount() {
    // Reveals the contents of the page once the layout has been mounted
    this.setState({ layoutHidden: false });
  }

  /**
   * This is a helper for rendering lists of links used in the footer.
   *
   * @param {Array} links
   * @memberof DefaultLayout
   */
  static renderFooterLinks(links) {
    return links.map(link => (
      <React.Fragment key={link.href}>
        <Footer.FooterLink key={link.href} href={link.href} onClick={link.onClick} rel={link.rel} target={link.target}>
          {link.label}
        </Footer.FooterLink>
        <StyledLink>{link?.subLabels}</StyledLink>
      </React.Fragment>
    ));
  }

  /**
   * Helper for looking up routes names.
   *
   * @returns {string} Route name for know routes. `UNKNOWN` for unknown routes.
   * @memberof DefaultLayout
   */
  getRouteName() {
    const { router } = this.props;

    const routeMatch = routes.match(router.asPath);
    const routeName = get(routeMatch, 'route.name', 'UNKNOWN');

    return routeName;
  }

  /**
   * The nav can be disabled on a per route basis.
   *
   * Update NAV_DISABLED_ROUTES to disable the nav on a route.
   *
   * @returns {boolean} True if the nav should be visible false if not.
   * @memberof DefaultLayout
   */
  isNavVisible() {
    return !DefaultLayout.NAV_DISABLED_ROUTES.includes(this.getRouteName());
  }

  /**
   * The nav search field can be forced open on a per route basis.
   *
   * Update NAV_SEARCH_OPEN_ROUTES to force the search field open on a route.
   *
   * @returns {boolean} True if the search field should be forced open false if not.
   * @memberof DefaultLayout
   */
  isNavSearchOpen() {
    return DefaultLayout.NAV_SEARCH_OPEN_ROUTES.includes(this.getRouteName());
  }

  /**
   * The footer can be disabled on a per route basis.
   *
   * Update FOOTER_DISABLED_ROUTES to disable the footer on a route.
   *
   * @returns {boolean} True if the footer should be visible false if not.
   * @memberof DefaultLayout
   */
  isFooterVisible() {
    return !DefaultLayout.FOOTER_DISABLED_ROUTES.includes(this.getRouteName());
  }

  /**
   * The search box on nav can be disabled on a per route basis.
   *
   * Update NAV_SEARCH_DISABLED_ROUTES to disable the search box on a route.
   *
   * @returns {boolean} True if the search box should be visible false if not.
   * @memberof DefaultLayout
   */
  isNavSearchVisible() {
    return !DefaultLayout.NAV_SEARCH_DISABLED_ROUTES.includes(this.getRouteName());
  }

  /**
   * Compose and render the footer.
   *
   * @returns {Component} The footer component.
   * @memberof DefaultLayout
   */
  static renderFooter() {
    return (
      <FooterContainer>
        {({ navRegionOne, navRegionTwo, navRegionThree, locale, social }) => (
          <LayoutFooter>
            <Footer.Wrapper
              columns={[
                <Footer.FooterColumn>
                  <SVGIcon selectedIcon="lodgelinkwhite" />
                </Footer.FooterColumn>,
                <Footer.FooterColumn heading={navRegionOne.heading}>
                  <Footer.FooterLinkList>{DefaultLayout.renderFooterLinks(navRegionOne.links)}</Footer.FooterLinkList>
                </Footer.FooterColumn>,
                <Footer.FooterColumn heading={navRegionTwo.heading}>
                  <Footer.FooterLinkList>{DefaultLayout.renderFooterLinks(navRegionTwo.links)}</Footer.FooterLinkList>
                </Footer.FooterColumn>,
                <Footer.FooterColumn>
                  <Footer.FooterLabel>{social.heading}</Footer.FooterLabel>
                  <Footer.FooterIconList>
                    {social.links.map(link => (
                      <Footer.FooterSVGLink key={link.href} {...link} />
                    ))}
                  </Footer.FooterIconList>
                </Footer.FooterColumn>
              ]}
              copyright={
                <Footer.FooterCopyright copyright={navRegionThree.heading}>
                  {DefaultLayout.renderFooterLinks(navRegionThree.links)}
                </Footer.FooterCopyright>
              }
              footerSelect={
                locale.list.length > 1 ? (
                  <Footer.FooterLocaleSelect
                    locales={locale.list}
                    selected={locale.selected}
                    onSelect={({ value }) => {
                      locale.change(value);
                    }}
                  />
                ) : (
                  undefined
                )
              }
            />
          </LayoutFooter>
        )}
      </FooterContainer>
    );
  }

  /**
   * Compose and render the navigation.
   *
   * @returns {Component} The navigation component.
   * @memberof DefaultLayout
   */
  renderNav() {
    return (
      <MainNavContainer>
        {({ mainNav }) => (
          <LayoutNav>
            <MainNav.MainNav
              authenticated={mainNav.authenticated}
              contactNumber={mainNav.contactNumber}
              joinLink={mainNav.joinLink}
              joinOrganization={mainNav.joinOrganizationLink}
              logo={mainNav.logo}
              mainLinks={mainNav.mainLinks}
              onContactClick={mainNav.onContactClick}
              organizations={mainNav.organizations}
              organizationChange={mainNav.organizationChange}
              organizationSettings={mainNav.organizationSettings}
              search={
                mainNav.authenticated &&
                this.isNavSearchVisible() && (
                  <PropertySearchContainer analyticLocation="global nav">
                    {({
                      onDatesChangeHook,
                      onRoomsChangeHook,
                      roomsValue,
                      datesStartDate,
                      datesEndDate,
                      onSearchChangeHook,
                      onHandleSearch,
                      searchLabelText,
                      roomsLabelText,
                      datesStartDateLabel,
                      datesEndDateLabel,
                      canSubmit,
                      ...otherArgs
                    }) => (
                      <MainNav.SearchBar
                        searchFieldRef={this.searchFieldRef}
                        onSubmitHook={onHandleSearch}
                        onChangeHook={onSearchChangeHook}
                        canSubmit={canSubmit}
                        {...otherArgs}
                      />
                    )}
                  </PropertySearchContainer>
                )
              }
              searchFieldRef={this.searchFieldRef}
              signIn={mainNav.signIn}
              signOut={mainNav.signOut}
              stickSearchOpen={this.isNavSearchOpen()}
              subMenuLinks={mainNav.subMenuLinks}
              suppressNotifications
              userCurrentOrganization={mainNav.userCurrentOrganization}
              userFirstName={mainNav.userFirstName}
              userLastName={mainNav.userLastName}
              isDemoSite={mainNav.isDemoSite}
            />
          </LayoutNav>
        )}
      </MainNavContainer>
    );
  }

  /**
   * Renders the contents of the page ensuring that the bottom of the footer
   * will never appear above the bottom of the window.
   *
   * @returns {Component} The page content.
   * @memberof DefaultLayout
   */
  renderPageContent() {
    const { children } = this.props;

    return <LayoutPageContent>{children}</LayoutPageContent>;
  }

  render() {
    const { layoutHidden } = this.state;
    return (
      <Grid.ScreenClassProvider>
        <Layout hidden={layoutHidden}>
          {/* Navigation */}
          {this.isNavVisible() && this.renderNav()}

          {/* Page Content */}
          {this.renderPageContent()}

          {/* Footer */}
          {this.isFooterVisible() && DefaultLayout.renderFooter()}
        </Layout>
      </Grid.ScreenClassProvider>
    );
  }
}

export default compose(withRouting)(DefaultLayout);
