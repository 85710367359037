import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';
import {
  DEFAULT_SIZE,
  DEFAULT_THEME,
  sizeNames,
  themeNames,
  StyledButton,
  NeutralAnchor,
  StyledSpinner,
  SpinnerWrapper
} from './Button.styles';

function Button(props) {
  const { children, href, target, fullWidth, fullHeight, name, loading, pill, theme } = props;
  const wrapSpinner = (
    <SpinnerWrapper pill={pill}>
      <StyledSpinner size={16} thickness={8} />
    </SpinnerWrapper>
  );
  const theChildren = loading ? (
    <>
      {children}
      {wrapSpinner}
    </>
  ) : (
    children
  );
  /** If we're passed an href prop, render it with an anchor tag */
  return href ? (
    <NeutralAnchor href={href} target={target} fullWidth={fullWidth} fullHeight={fullHeight}>
      <StyledButton aria-label={name} {...props} theme={theme?.type ?? theme}>
        {theChildren}
      </StyledButton>
    </NeutralAnchor>
  ) : (
    <StyledButton {...props} theme={theme?.type ?? theme}>
      {theChildren}
    </StyledButton>
  );
}

Button.propTypes = {
  /**
   * Boolean prop that toggles between display: block / inline-block. Defaults to inline-block
   * */
  block: PropTypes.bool,

  /**
   * className binding for styled-components
   */
  className: PropTypes.string,

  /**
   * Boolean prop to toggle the disabled button state.
   */
  disabled: PropTypes.bool,

  /**
   * Boolean prop to toggle height:100%
   */
  fullHeight: PropTypes.bool,

  /**
   * Boolean prop to toggle width: 100% -- affects the anchor tag
   */
  fullWidth: PropTypes.bool,

  /**
   * If a 'href' tag is provided, the button will render wrapped in an anchor element with styling intact
   */
  href: PropTypes.string,

  /**
   * Loading flag - displays a spinner on the right side of children if loading
   */
  loading: PropTypes.bool,

  /**
   * HTML Name attribute
   * */
  name: PropTypes.string,

  /**
   * onClick callback function
   */
  onClick: PropTypes.func,

  /**
   * Boolean flag to turn on pill mode which adds a border-radius and adjusts padding based on 'size'
   */
  pill: PropTypes.bool,

  /**
   * Specifies the size of the button: lg or sm. Affects max-width and padding.
   */
  size: PropTypes.oneOf(sizeNames),

  /**
   * Provide a target for your href tag
   */
  target: PropTypes.oneOf(['_blank', '', null]),

  /**
   * Pass in a theme name corresponding to a theme inside Button.theme
   * AVAILABLE THEMES: blue, grey
   */
  theme: PropTypes.oneOf(themeNames),

  /**
   * Defines HTML button type attribute
   */
  type: PropTypes.oneOf(['button', 'submit', 'reset', null]),

  /**
   * Uppercase boolean
   */
  uppercase: PropTypes.bool
};

Button.defaultProps = {
  block: false,
  className: null,
  disabled: false,
  fullHeight: false,
  fullWidth: false,
  href: null,
  loading: false,
  name: null,
  onClick: () => {},
  pill: false,
  size: DEFAULT_SIZE,
  target: null,
  theme: DEFAULT_THEME,
  type: 'button',
  uppercase: false
};

export default withTheme(Button);
