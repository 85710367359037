import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { transform, defaultConverters } from './lib';

function RichText(props) {
  const { content, converters, whitelist, allowedObjects } = props;

  const mergedConverters = {
    ...defaultConverters,
    ...converters
  };

  // Sanitize and parse content
  const sanitizedContent = DOMPurify.sanitize(content, {
    FORBID_TAGS: ['script'], // Prevents script injection
    ALLOWED_TAGS: Object.keys(mergedConverters) // Only allow tags that have converters
  });

  return (
    <>
      {parse(sanitizedContent, {
        replace: node => transform(node, { allowedObjects, convert: mergedConverters, whitelist })
      })}
    </>
  );
}

RichText.propTypes = {
  content: PropTypes.string,
  converters: PropTypes.object, // eslint-disable-line
  whitelist: PropTypes.arrayOf(PropTypes.string),
  allowedObjects: PropTypes.arrayOf(PropTypes.string)
};

RichText.defaultProps = {
  content: '',
  whitelist: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'strong', 'br', 'a', 'ul', 'li', 'table', 'tbody', 'tr', 'td'],
  allowedObjects: [],
  converters: {}
};

export default RichText;
