import React from 'react';

/**
 * All the magic happens here.
 *
 * Transforms plain html in to React elements
 *
 * @param {Element} node
 * @param {options.convert} convert - config file that handles conversions. See /conveters folder.
 * @param {options.whitelist} whitelist - html elements to accept.
 */
function transform(node, options) {
  const { convert = {}, whitelist = [] } = options ?? {};
  const processChildren = children => children.map(child => transform(child, options));

  /**
   * Return null if we're ignoring this element
   *
   * This will fail if ALL of these conditions are present:
   * a) Invalid/undefined node name
   * b) Node/element name is not whitelisted explicitly
   * c) There is no converter defined for the node
   */
  if (node.name !== undefined && (!whitelist.includes(node.name) || !convert[node.name])) {
    return null;
  }

  // If the convert object has a transform defined for this type of element
  if (Object.keys(convert).includes(node.name)) {
    const Element = convert[node.name];
    // If the object has a "transform" property that is a function, use that.
    if (typeof Element === 'function') {
      return Element(node, undefined, {
        props: Element.props,
        transform: children => transform(children, options),
        ...options
      });
    }

    if (typeof Element === 'object') {
      // Fallback on the "default" convention of { element: ReactComponent, props: { disabled: true } }
      return (
        // eslint-disable-next-line react/jsx-pascal-case
        <Element.element {...Element.props}>{processChildren(node.children)}</Element.element>
      );
    }

    // No transform defined just return the element exatly as it was
    return <Element>{processChildren(node.children)}</Element>;
  }

  // Undefined will convert to plain html
  return node.type === 'text' ? <span>{node.data}</span> : undefined;
}

export default transform;
