const get = require('lodash.get');
const PropTypes = require('prop-types');
const React = require('react');

/**
 * List of all supported locales.
 *
 * When adding enabling a new locale there is some code that needs to be un-commented in:
 * - client/polyfills.js
 * - pages/_app.js
 */
const locales = [
  {
    id: 'en', // URL slug to represent this locale
    locale: 'en-CA', // ISO 639 (https://en.wikipedia.org/wiki/ISO_639)
    label: 'Canada - English' // Label for display in the footers locale selector
  }
  // {
  //   id: 'fr',
  //   locale: 'fr-CA',
  //   label: 'Canada - French'
  // }
  // {
  //   id: 'en-us',
  //   locale: 'en-US',
  //   label: 'United States - English'
  // },
  // {
  //   id: 'en-au',
  //   locale: 'en-AU',
  //   label: 'Australia - English'
  // }
];

/**
 * Define a default locale.
 */
const defaultLocale = locales[0].id;

/**
 * Get a locale by providing it's ID.
 *
 * @param {string} id Locale id.
 */
const getLocale = id => locales.find(locale => locale.id === id);

/**
 * Get all supported locales.
 */
const getLocales = () => locales;

/**
 * Get the default locale.
 */
const getDefaultLocale = () => locales.find(locale => locale.id === defaultLocale);

/**
 * Get a list of all locale id's.
 */
const getLocaleIds = () => locales.map(locale => locale.id);

/**
 * Validate a locale ID to make sure it is one of the supported locales.
 *
 * @param {string} id Locale ID to validate.
 */
const isValidLocale = id => locales.find(locale => locale.id === id) !== undefined;

/**
 * Create a context to hold the current locale.
 */
const LocaleContext = React.createContext(getDefaultLocale());
const LocaleProvider = LocaleContext.Provider;
const LocaleConsumer = LocaleContext.Consumer;

/**
 * A regular expression that strips the locale from beginning of a route.
 *
 * [/en-us]/path
 */
const regexGetLocale = new RegExp(`^/(${getLocaleIds().join('|')})`);

const getActiveLocaleFromContext = ctx => {
  const { asPath, err, req, query } = ctx;

  // No matter what we are going to at least return the default locale
  let locale = getDefaultLocale();

  // First we check to see if the server has included the locale on the request
  if (req && req.locale && isValidLocale(req.locale)) {
    locale = getLocale(req.locale);
  }
  // If it did not come through from the server we check the client side route for the locale param
  else if (query && query.locale && isValidLocale(query.locale)) {
    locale = getLocale(query.locale);
  }
  // The _error page needs to be handled in a special way.
  // We have not defined a route for error pages so the locale does not get parsed automatically.
  else if (err !== undefined) {
    // This regex will try to find a locale at the beginning of a url
    const matchResults = asPath.match(regexGetLocale);
    const localeMatch = get(matchResults, '[1]') ?? 'en';

    // If a locale was found and is valid it gets used
    if (localeMatch && isValidLocale(localeMatch)) {
      locale = getLocale(localeMatch);
    }
  }

  return locale;
};

/**
 * A re-usable prop type describing the shape of a locale object.
 */
const localePropType = PropTypes.shape({
  id: PropTypes.oneOf(getLocales().map(locale => locale.id)),
  locale: PropTypes.oneOf(getLocales().map(locale => locale.locale)),
  label: PropTypes.string.isRequired
});

/**
 * Using CommonJS because this is consumed both client and server side.
 */
module.exports = {
  defaultLocale,
  getDefaultLocale,
  getLocale,
  getLocales,
  getLocaleIds,
  isValidLocale,
  localePropType,
  LocaleProvider,
  LocaleConsumer,
  regexGetLocale,
  getActiveLocaleFromContext
};
